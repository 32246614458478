@font-face {
  font-family: 'Versa';
  src: url('./assets/fonts/Versa-Versa.otf') format('opentype');
}

@font-face {
  font-family: 'Nexa Light';
  src: url('./assets/fonts/Nexa-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/Nexa-Heavy.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-extrabold';
  src: url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font: 14px 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::selection { 
  background-color: #fa951a !important; 
  color: #fff; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fefefe;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #2d373f;
  border-radius: 12px;
}
